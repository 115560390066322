<template>
  <div class="home" style="overflow:hidden">
    <div class="big-banner ratio ratio-21x9 animated-background"></div>
    <div class="container">
      <div class="row justify-content-center mt-4 mt-md-5">
        <div class="col-11">
          <div class="ratio ratio-21x9 animated-background"></div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto mt-4">
          <h3 class="mt-4 mb-4 mb-md-5 mt-md-5 pb-2 text-dark text-center fw-medium fw-md-light border-bottom border-dark border-2">最新活動</h3>
        </div>
        <div class="col-12">
          <div class="row gy-3">
            <div class="col-12 col-md-6">
              <div class="ratio ratio-4x3 animated-background"></div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ratio ratio-4x3 animated-background"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto mt-4">
          <h3 class="mt-4 mb-4 mb-md-5 mt-md-5 pb-2 text-dark text-center fw-medium fw-md-light border-bottom border-dark border-2">季節限定</h3>
        </div>
        <div class="col-12">
          <div class="row gy-3">
            <div class="col-12 col-md-6">
              <div class="ratio ratio-4x3 animated-background"></div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ratio ratio-4x3 animated-background"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto mt-4">
          <h3 class="mt-4 mb-4 mb-md-5 mt-md-5 pb-2 text-dark text-center fw-medium fw-md-light border-bottom border-dark border-2">最新資訊</h3>
        </div>
        <div class="col-12">
          <div class="row gy-3">
            <div class="col-12 col-md-6">
              <div class="ratio ratio-4x3 animated-background"></div>
            </div>
            <div class="col-12 col-md-6">
              <div class="ratio ratio-4x3 animated-background"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-auto mt-4">
          <h3 class="mt-4 mb-4 mb-md-5 mt-md-5 pb-2 text-dark text-center fw-medium fw-md-light border-bottom border-dark border-2">明星商品</h3>
        </div>
        <div class="col-12 d-none d-md-block">
          <div class="row gy-3">
            <div class="col-3">
              <div class="card border-0">
                <div class="ratio ratio-1x1 animated-background"></div>
                <div class="card-body px-0 pt-2 animated-background">
                  <h5 class="animated-background card-title card-title-height animated-background"></h5>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card border-0">
                <div class="ratio ratio-1x1 animated-background"></div>
                <div class="card-body px-0 pt-2 animated-background">
                  <h5 class="animated-background card-title card-title-height animated-background"></h5>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card border-0">
                <div class="ratio ratio-1x1 animated-background"></div>
                <div class="card-body px-0 pt-2 animated-background">
                  <h5 class="animated-background card-title card-title-height animated-background"></h5>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card border-0">
                <div class="ratio ratio-1x1 animated-background"></div>
                <div class="card-body px-0 pt-2 animated-background">
                  <h5 class="animated-background card-title card-title-height animated-background"></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row my-5">
        <div class="col-12 mb-5">
          <div class="ratio ratio-21x9 animated-background"></div>
        </div>
      </div>
    </div>
  </div>
</template>
